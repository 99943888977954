const buttonPrev = document.querySelector(".swiper-button-prev");
const buttonNext = document.querySelector(".swiper-button-next");
const slides = Array.from(document.querySelectorAll(".slideshow li span"));
let currentIndex = 0;
let intervalId;

buttonNext.addEventListener("click", advanceSlide);
buttonPrev.addEventListener("click", reverseSlide);

const stopRotateSlides = () => {
  clearInterval(intervalId);
  // release intervalID from the variable
  intervalId = null;
};

const goToNext = () => {
  // get the key for the current slide
  // and cast it as a number so can use as array index
  let currentSlide = document.getElementsByClassName("current");
  let currentIndex = parseInt(currentSlide[0].dataset.key);
  // remove class current from that slide
  currentSlide[0].classList.remove("current");
  // if at end of array, go to the first
  if (currentIndex === slides.length - 1) {
    slides[0].classList.add("current");
    // otherwise, just go to the next
  } else {
    slides[currentIndex + 1].classList.add("current");
  }
};

const goToPrev = () => {
  // get the key for the current slide
  // and cast it as a number so can use as array index
  let currentSlide = document.getElementsByClassName("current");
  let currentIndex = parseInt(currentSlide[0].dataset.key);
  // remove class current from that slide
  currentSlide[0].classList.remove("current");
  // if at beginning of array, go to the last item in array
  if (currentIndex === 0) {
    slides[slides.length - 1].classList.add("current");
    // otherwise, just go to the prev
  } else {
    slides[currentIndex - 1].classList.add("current");
  }
};

const rotateSlides = () => {
  if (!intervalId) {
    intervalId = setInterval(goToNext, 6000);
  }
};

// let user manually advance slides one by one
function advanceSlide(e) {
  if (intervalId) {
    stopRotateSlides();
  }
  goToNext();
}
// let user manually reverse slides one by one
function reverseSlide(e) {
  if (intervalId) {
    stopRotateSlides();
  }
  goToPrev();
}

// automatically start endless slide looping on page load
rotateSlides();
